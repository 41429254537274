import firebase from 'firebase/app';
import 'firebase/auth'

const firebaseConfig = {
    apiKey: "AIzaSyB9X1NL4T6bWonqiMX1kXb4_AKPNINMgKY",
    authDomain: "image-classifier-7dd07.firebaseapp.com",
    databaseURL: "https://image-classifier-7dd07.firebaseio.com",
    projectId: "image-classifier-7dd07",
    storageBucket: "image-classifier-7dd07.appspot.com",
    messagingSenderId: "860476183016",
    appId: "1:860476183016:web:0eee426bf2182ab161145d",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth()